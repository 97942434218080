import { SuggestionSelectedEventData } from 'react-autosuggest'
import compact from 'lodash/compact'
import { history } from '@/browserHistory'
import { TOnSuggestionsFetchRequested } from '@/components/ui/fields/Autosuggest/interfaces'
import { ESuggestionType, TMarketSearchSuggestion, TMarketSearchSuggestions } from '@/services/MarketSearchService/interfaces'
import MarketSearchService from '@/services/MarketSearchService/MarketSearchService'
import { EAgriculturalProductsRoute } from '@/types'
import { EAgriculturalProductCategoryId } from '@/types/Catalog'
import { TFormatMessage } from '@/types/TFormatMessage'
import { pairwise } from '@/utils/helpers'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'
import { SuggestionTypeFilterName } from './constants'

export const convertToSuggestionsWithDividers = (rawSuggestions: TMarketSearchSuggestions): TMarketSearchSuggestions => {
  const convertedSuggestions: TMarketSearchSuggestions = []

  if (rawSuggestions?.length) {
    pairwise(rawSuggestions, (current, next) => {
      if (current.type !== next.type) {
        convertedSuggestions.push({ ...current, withDivider: true })
      } else convertedSuggestions.push(current)
    })

    convertedSuggestions.push(rawSuggestions[rawSuggestions.length - 1]!)
  }

  return convertedSuggestions
}

export const getSuggestionLink = (suggestion: TMarketSearchSuggestion): string => {
  const { id, type } = suggestion

  switch (suggestion.type) {
    case ESuggestionType.CATEGORY:
      return createRouterLink(EAgriculturalProductsRoute.category, { categoryId: id })
    case ESuggestionType.SKU:
      return createRouterLink(EAgriculturalProductsRoute.product, { category: suggestion.category.id, productId: id })
    case ESuggestionType.PRODUCER:
    case ESuggestionType.SUPPLIER:
      return getUrlWithQuery(createRouterLink(EAgriculturalProductsRoute.category, { categoryId: EAgriculturalProductCategoryId.SEARCH }), {
        [SuggestionTypeFilterName[type]!]: id,
      })
    case ESuggestionType.ACTIVE_SUBSTANCES:
      return getUrlWithQuery(
        createRouterLink(EAgriculturalProductsRoute.category, { categoryId: EAgriculturalProductCategoryId.remedies }),
        { [SuggestionTypeFilterName[type]!]: id },
      )
    default:
      return ''
  }
}

export const makeCategoryParts = (formatMessage: TFormatMessage) => (suggestion: TMarketSearchSuggestion) => {
  const categoryParts: string[] = []

  if (suggestion.type === ESuggestionType.SKU) {
    categoryParts.push(suggestion.category.name)
  } else {
    categoryParts.push(formatMessage({ id: `searchInput.suggestion.category.${suggestion.type}` }))
  }

  return compact(categoryParts)
}

export const onSuggestionSelected = async ({ suggestion, method }: SuggestionSelectedEventData<TMarketSearchSuggestion>) => {
  const suggestionLink = getSuggestionLink(suggestion)
  if (suggestionLink && method === 'enter') history.push(suggestionLink)

  return suggestion
}

const marketSearchServices = new MarketSearchService()

export const onSearchAgrariansFetchRequested: TOnSuggestionsFetchRequested<TMarketSearchSuggestion> = async ({ value, statuses }) => {
  const suggestions = await marketSearchServices.getAgrariansFromBuyersLk(value)

  return Promise.resolve(convertToSuggestionsWithDividers(suggestions))
}

export const onSearchSellersFetchRequested: TOnSuggestionsFetchRequested<TMarketSearchSuggestion> = async ({ value, statuses }) => {
  const suggestions = await marketSearchServices.getAgrariansOrganizationsFromAccreditation({ statuses, searchField: value })

  return Promise.resolve(convertToSuggestionsWithDividers(suggestions))
}
